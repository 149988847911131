import React, { useState, useContext, useEffect, useMemo } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import "twin.macro"
import Cookies from "js-cookie"
import { useLocation } from "@reach/router"
import Checkbox from "lib/Checkbox"
import {
  Button,
  ButtonGroup,
  Responsive,
  Form,
  FormField,
  ValidationInput,
  Loading,
} from "@clevertrack/shared"
import { authenticate, isLoggedIn } from "services/auth"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import emailValidation from "utils/emailValidation"
import cogoToast from "@clevertrackdk/cogo-toast"
import { UserContext } from "app/User/context"
import { UserActions, UserActionTypes } from "app/User/actions"
import { queryResolver } from "utils/queryResolver"
import { useTranslation } from "react-i18next"

const StyledLogin = styled.div`
  position: relative;
  width: 80vw;

  ${(props) => props.theme.media.tablet_portrait_up`
    max-width: 60rem;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 2rem auto 0;
    max-width: 75%;
  `}

  ${(props) => props.theme.media.desktop_up`
    max-width: 55%;
  `}
`

const CheckboxField = styled.div`
  margin-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const StyledSysMsg = styled.small`
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: 400;
  font-size: 75%;
  ${(props) => props.theme.media.tablet_portrait_up`
    float: right;
  `}
`

function Login({ children, ...props }) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [remember, setRemember] = useState(false)
  const { dispatch } = useContext(UserContext)
  const location = useLocation()
  const { t } = useTranslation()

  const urlParams: { [key: string]: string } | undefined = useMemo(() => {
    if (location.search) {
      setLoading(true)
      return queryResolver(location.search)
    }
  }, [location])

  const isUserLoggedIn = async () => {
    try {
      const result = await isLoggedIn()
      if (result && result.authenticated && typeof window !== `undefined`) {
        dispatch(UserActions(UserActionTypes.SetUser, { user: result.user }))
        navigate(`/app${location.search}`)
      }
    } catch (error) { }
  }

  function onCheckHandler(value) {
    setRemember(value)
  }

  async function onAuthenticate(e) {
    e.preventDefault()
    setLoading(true)

    // if username includes @clevertrack.dk

    const isCmsAdmin = username.endsWith("@clevertrack.dk") ? 1 : 0

    try {
      const { authenticated, user } = await authenticate(
        username,
        password,
        remember,
        isCmsAdmin
      )
      if (!authenticated) {
        setLoading(false)
        cogoToast.error("Du har ikke adgang til systemet.")
      } else {
        dispatch(UserActions(UserActionTypes.SetUser, { user: user }))
        setLoading(false)
        navigate("/app")
      }
    } catch (error) { }
  }

  useEffect(() => {
    isUserLoggedIn()
  }, [])

  const defaultValidationMessages = getDefaultValidationMessagesByLang("da")
  const validationMessages = {
    ...defaultValidationMessages,
    customError: "Ups! Indtast en korrekt email.",
  }

  useMemo(() => {
    if (urlParams?.token) {
      urlParams?.installer ? Cookies.set("clevertrack-hub-authenticated", urlParams.token) : Cookies.set("clevertrack-hub-authenticated-installer", urlParams.token)
      isUserLoggedIn()
    }
  }, [urlParams])

  const renderLoginLoader = () => {
    return <Loading loadingText={t("login_loading_text")} />
  }

  return loading ? (
    renderLoginLoader()
  ) : (
    <StyledLogin {...props}>
      <h2 tw="text-center mt-0">Login</h2>
      <Form onSubmit={onAuthenticate}>
        <FormField validationKey="email" label="Email">
          <ValidationInput
            required
            id="email"
            type="email"
            placeholder="Indtast email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            customValidation={emailValidation}
            validationMessages={validationMessages}
          />
        </FormField>
        <FormField validationKey="password" label="Password">
          <ValidationInput
            id="password"
            type="password"
            placeholder="Indtast password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormField>
        <CheckboxField tw="mt-8">
          <Checkbox
            onChange={onCheckHandler}
            appearance="toggle"
            checked={remember}
          >
            <span>Husk mig</span>
          </Checkbox>
        </CheckboxField>
        <Responsive
          phone={
            <ButtonGroup direction="column">
              <Button type="submit" variant="primary">
                Log ind
              </Button>
              <Link to="/app/reset-password">
                <Button invert appearance="link" type="button">
                  Glemt password
                </Button>
              </Link>
            </ButtonGroup>
          }
          tabletPortrait={
            <ButtonGroup position="between" tw="py-8">
              <Link to="/app/reset-password">
                <Button invert appearance="link" type="button">
                  Glemt password
                </Button>
              </Link>
              <Button type="submit" variant="primary">
                Log ind
              </Button>
            </ButtonGroup>
          }
        />
      </Form>
    </StyledLogin>
  )
}

export default Login

Login.defaultProps = {}
Login.propTypes = {}
